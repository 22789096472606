<template>
  <div>
    <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ userData.name }}
          </p>
          <div class="user-status">
            {{ userData.roles[0] }}<span
              v-if="exibirSetorAtual"
              class="text-uppercase"
          > | {{ exibirSetorAtual }}</span>
          </div>
        </div>
        <b-avatar
            size="40"
            :src="userData.imagem"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
        >
          <feather-icon
              v-if="!userData.name"
              icon="UserIcon"
              size="22"
          />
        </b-avatar>
      </template>

      <!-- Abre a pagina do editar usuario com os dados da pessoal logada -->
      <b-dropdown-item
          :to="{ name: 'perfil'}"
          link-class="d-flex align-items-center"
      >
        <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
        />
        <span>Perfil</span>
      </b-dropdown-item>
      <b-dropdown-item
          v-if="$can('personificar', 'users') && !exibirDespersonificar"
          @click="abrirModalPersonificar()"
          link-class="d-flex align-items-center"
      >
        <feather-icon
            size="16"
            icon="RefreshCcwIcon"
            class="mr-50"
        />
        <span>Personificar</span>
      </b-dropdown-item>
      <b-dropdown-item
          v-if="$can('personificar', 'users') && exibirDespersonificar"
          @click="despersonificarUsuario()"
          link-class="d-flex align-items-center"
      >
        <feather-icon
            size="16"
            icon="RefreshCcwIcon"
            class="mr-50"
        />
        <span>Despersonificar</span>
      </b-dropdown-item>

      <b-dropdown-divider/>

      <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
      >
        <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
        />
        <span>Sair</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
    <b-modal
        id="modalPersonificar"
        title="Personificar"
        size="sm"
        hide-footer
        centered
    >
      <b-row>
        <b-col cols="12">
          <b-form-group label="Escolha um Usuário">
            <select-com-pesquisa
                placeholder="Escolha um usuário"
                url="/users"
                :itensParaEdicao="[]"
                @selecionados="personificarUsuario"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import {
  BAvatar,
  BCol,
  BDropdownDivider,
  BDropdownItem,
  BFormGroup,
  BModal,
  BNavItemDropdown,
  BRow
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'

export default {
  components: {
    SelectComPesquisa,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BFormGroup,
    BModal,
    BCol,
    BRow
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      exibirDespersonificar: false
    }
  },
  computed: {
    // ? You can also use vuex to get userData
    userDataStore() {
      return this.$store.state.app.user
    },
    exibirSetorAtual() {
      return this.userDataStore && this.userDataStore.preferences && this.userDataStore.preferences.setorAtual
    },
  },
  mounted() {
    this.$store.dispatch('app/atualizarSessaoUsuario')
    this.verificaUsuarioPersonificado()
  },
  methods: {
    abrirModalPersonificar() {
      this.$bvModal.show('modalPersonificar')
    },
    personificarUsuario(usuario) {
      this.$store.dispatch('app/personificarUsuario', usuario.id)
          .then(() => {
            window.location.href = '/'
          })
    },
    verificaUsuarioPersonificado() {
      const OldPersonificateToken = localStorage.getItem('OldPersonificateToken')
      if (OldPersonificateToken) {
        this.exibirDespersonificar = true
      }
    },
    async despersonificarUsuario() {
      const oldPersonificateToken = localStorage.getItem('oldPersonificateToken')
      const oldUserData = localStorage.getItem('oldUserData')

      localStorage.setItem('accessToken', oldPersonificateToken)
      localStorage.setItem('userData', oldUserData)
      localStorage.removeItem('oldPersonificateToken')
      localStorage.removeItem('newPersonificateToken')
      localStorage.removeItem('oldUserData')
      await this.$store.dispatch('app/setOnlineStatus', false)
      setTimeout(() => {
        window.location.reload()
      }, 1000)

    },
    async logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token

      await this.$store.dispatch('app/setOnlineStatus', false)
      await this.$store.dispatch('app/logoutSistema')
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
